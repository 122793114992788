@use '_variables.scss' as *;

ul.tools{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    i { font-size: 3.5rem; }
    li {
        align-items: center;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px;
        position: relative;
        width: 8%;
        p {
            font-family: $header-font;
            color: $h3;
            font-size: 1.4rem;
            line-height: 1.5rem;
            margin: 0;
            opacity: 0;
            position: absolute;
            text-align: center;
        }
        p, i { transition: 0.3s; }
        &:hover,
        &:focus {
            p { opacity: 1; }
            i { opacity: 0; }
        }
    }
}