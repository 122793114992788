@use '_variables.scss' as *;

html { background-color: $bg; }

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    min-height: 100svh;
}

.wrapper {
    margin: 0 auto;
    max-width: 1000px;
    width: 65%;
}

main { padding-top: 2rem; }

ul { list-style-type: square; }
ul ul { list-style-type: disc; }
li { padding: 0.5rem 0; }