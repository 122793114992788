.project-header {
    align-items: center;
    display: flex;
    p.in-dev { font-size: 1.4rem; }
}

ul.project-tech {
    display: flex;
    list-style-type: none;
    padding: 0;
    li {  margin: 0px 3px; }
}

ul.project-links {
    list-style-type: "|";
    > li:first-child  { list-style-type: none; }
    display: flex;
    padding-left: 10px;
    li {
        margin-right: 5px;
        padding: 0 10px;
    }
}