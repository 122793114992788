@use '_variables.scss' as *;

html {
    color: $body;
    font-family: $body-font;
    font-size: 62.5%;
}

h1, h2, h3 { font-family: $header-font; }

h1 a {
    background-color: $black;
    border: 2px solid $white;
    color: $white;
    display: block;
    font-size: 4rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    padding-top: 0.5rem;
    text-decoration: none;
    width: fit-content;
    rotate: -1.2deg;
    transition: 0.3s;
    &:hover {border-color: $link;}
}

h2 {
    color: $h2;
    font-size: 2.7rem;
}

h3 {
    color: $h3;
    font-size: 2.2rem;
    margin: 0;
}

p, li {
    font-size: 1.8rem;
    line-height: 2.8rem;
}

a {
    color: $link;
    transition: 0.3s;
    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
    }
}

footer p,
footer a {
    font-size: 1.4rem;
    margin: 0;
}

#error-404 { text-align: center; }