@use '_variables.scss' as *;

header {
    position: relative;
    padding: 20px 0 10px 0;
    img {
        z-index: -1;
        position: absolute;
        top :0;
        object-fit: fit;
        width: 100%;
        height: 120%;
        filter: saturate(0.9);
        -webkit-filter: saturate(0.9);
    }
    ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            transition: 0.3s;
            a { color: $black; }
            a:hover,
            a:focus { color: $link; }
            i {
                font-size: 3rem;
                padding: 0px 10px;
            }
        }
    }
}

footer {
    position: relative;
    margin-top: 60px;
    width: 100%;
    .footer-copy {
        display: flex;
        justify-content: space-between;
        margin: 35px 45px;
    }
    img {
        height: 30px;
        width: 100%;
        z-index: -1;
        position: absolute;
        bottom :0;
        object-fit: fit;
        width: 100%;
        height: 40px;
        filter: saturate(0.9);
        -webkit-filter: saturate(0.9);
    }
}