//fonts
$body-font: "Space Grotesk", sans-serif;
$header-font: "Outfit", sans-serif;

//colors
$bg: #f8f8ff;
$white: #f8f8ff;
$black: #2d2e2b;
$body: #2d2e2b;

$h2: #5E94C9;
$h3: #539D72;
$link: #955F8C;