@use '_variables.scss' as *;

@media (max-width: 1060px) {
    .project-header {
        align-items: start;
        flex-direction: column;
    }
    ul.project-links {
        padding-left: 0;
        > li:first-child { padding-left: 0; }
    }
    #the-collab-lab img.workflow { width: 100%; }
}

@media (max-width: 770px) {
    .wrapper { width: 80%; }
    h1 a { font-size: 3.5rem; }
    .project-header {
        flex-direction: column;
        align-items: start;
    }
    #the-collab-lab img.logo {
        width: 30%;
        margin: 10px auto;
        float: none;
    }
    ul.project-links {
        flex-direction: column;
        list-style-type: disc;
        padding-left: 20px;
        > li:first-child {
            list-style-type: disc;
            padding-left: 10px;
        }
    }
    ul.tools li {width: 13%;}
    footer p,
    footer a { font-size: 1.2rem; }
    .footer-copy {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 430px) {
    .wrapper { width: 90%; }
    h1 a { font-size: 2.5rem; }
    h2 { font-size: 2rem; }
    h2 { font-size: 1.8rem; }
    h3 { font-size: 1.6rem; }
    p, li {
        font-size: 1.4rem;
        line-height: 2.4rem;
    }
}